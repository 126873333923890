<template>
  <div id="Home">
    <div :class="$store.state.lang == 'en' ? 'english' : ''">
      <div class="header" v-show="is_login" @quit_id="quit_id">
        <div class="logo">
          <img src="https://newstore.vynior.com/hthm1.png" alt="" />
        </div>

        <div class="fist_page" v-show="$store.state.lang == 'cn'">
          <img src="https://newstore.vynior.com/hm4.png" alt="" />
        </div>
        <div class="fist_page" v-show="$store.state.lang == 'en'">
          <img src="https://newstore.vynior.com/homeshome.png" alt="" />
        </div>
        <div class="right">
          <div
            class="language_en"
            :ckass="change_language != 0 ? 'change_language_en' : ''"
            @click="langChange(en)"
            v-show="$store.state.lang == 'cn'">
            <!-- <img src="https://newstore.vynior.com/hm5.png" alt="" /> -->
            <span>En</span>
          </div>
          <div
            class="language_cn"
            :ckass="change_language != 0 ? 'change_language_en' : ''"
            @click="langChange(cn)"
            v-show="$store.state.lang == 'en'">
            <!-- <img src="https://newstore.vynior.com/hm6.png" alt="" /> -->
            <span>中文</span>
          </div>
        </div>
      </div>
      <Header v-show="is_logins" :user_name="user_name"></Header>

      <div class="content">
        <div class="carousel">
          <!-- <img src="https://newstore.vynior.com/htsy1.png" alt="" /> -->
          <!-- //note 轮播图 -->
          <div class="block">
            <el-carousel>
              <el-carousel-item v-for="(item, index) in carouse_arr" :key="index">
                <img style="border-radius: 8px" :src="item" alt="" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>

        <!-- note表单 -->
        <div class="form" v-show="is_login">
          <div class="Title">
            <p>{{ $t(`message.Home.Title`) }}</p>
          </div>

          <div class="loginStyle">
            <div class="One" :class="check[0] == 1 ? 'isactive' : ''" @click="checks(0)">
              <span>{{ $t(`message.Home.emailLogin`) }}</span>
            </div>
            <div class="Two" :class="check[1] == 1 ? 'isactive' : ''" @click="checks(1)">
              <span>{{ $t(`message.Home.phoneLogin`) }}</span>
            </div>
          </div>
          <!--note ---------------------邮箱登录-------------------- -->
          <div v-show="check[0]">
            <div class="email" :class="focusEmail == 1 ? 'email_bottom' : ''">
              <div class="people">
                <img src="https://newstore.vynior.com/hthm9.png" alt="" />
              </div>
              <form :class="email_err == 1 ? 'clear_text' : ''">
                <input type="text" :placeholder="$t(`message.Home.Address`)" v-model="email_val" @focus="focus_email" @blur="blur_email" />
              </form>
            </div>
            <div class="err_message" v-if="Not_email">{{ $t(`message.Home.emailLogin`) }}</div>
            <div class="err_message" v-if="email_err">{{ $t(`message.Home.emailError`) }}</div>
            <!-- 密码 -->
            <div class="password" :class="[email_err || Not_email ? '' : 'password_gap', focusPwd ? 'pwd_bottom' : '']">
              <div class="core">
                <img src="https://newstore.vynior.com/hthm10.png" alt="" />
              </div>
              <form :class="email_err == 1 ? 'clear_text' : ''">
                <input
                  :type="pasType ? 'password' : 'text'"
                  :placeholder="$t(`message.Home.Password`)"
                  autocomplete="autofocus"
                  ref="content"
                  v-model="password_val"
                  @focus="focus_pwd"
                  @blur="blur_pwd" />
              </form>
              <img
                v-show="eyeInfo && password_val"
                :src="pasType ? 'https://newstore.vynior.com/Outlineeyes.png' : 'https://newstore.vynior.com/Outlineeye.png'"
                class="paseye"
                @click.stop="eyeChange"
                alt="" />
              <div class="amend" @click="forgetPassword">
                <span>{{ $t(`message.Home.forgetPwd`) }}</span>
              </div>
            </div>
            <div class="err_message" v-show="password_err">{{ $t(`message.Home.passwordError`) }}</div>
            <!-- 十五天内 -->
            <div class="remember" :class="password_err ? '' : 'password_gaps'">
              <!-- <input type="checkbox" id="abc" class="checkbox" />
            <label for="abc">15天内记住密码</label> -->
              <el-checkbox v-model="remember_day">{{ $t(`message.Home.autoLogin`) }}</el-checkbox>
            </div>
          </div>
          <!--note ----------------------手机号登录 -------------------->
          <div v-show="check[1]">
            <!-- 手机号验证 -->
            <div class="phoneNumber" :class="focus_numbers == 1 ? 'phoneNumber_bottom' : ''">
              <div class="people">
                <img src="https://newstore.vynior.com/hthj10.png" alt="" />
              </div>
              <form>
                <input type="text" :placeholder="$t(`message.Home.mobile`)" v-model="user_phone" @blur="rex_number" @focus="focus_number" />
              </form>
              <div
                class="amends"
                :class="[user_phone != '' ? 'blue' : '', isGrey == true ? 'grey' : '']"
                v-bind:disabled="dis"
                type="primary"
                @click="getCode"
                v-show="$store.state.lang == 'en'">
                <span v-if="show" class="send_num">{{ $t(`message.Home.Verification`) }}</span>
                <span v-else>{{ $t(`message.Home.Resend`) }}({{ count }}s)</span>
              </div>

              <div
                class="amends"
                :class="[user_phone != '' ? 'blue' : '', isGrey == true ? 'grey' : '']"
                v-bind:disabled="dis"
                type="primary"
                @click="getCode"
                v-show="$store.state.lang == 'cn'">
                <span v-if="show" class="send_num">{{ $t(`message.Home.Verification`) }}</span>
                <span v-else>{{ count }}s{{ $t(`message.Home.Resend`) }}</span>
              </div>
            </div>
            <!-- <div class="err_phonNumber" v-show="err_phoneNumber == 1">手机号码不正确，请重新输入！</div>
          <div class="err_phonNumber" v-show="err_phoneNumber == 2">手机号码未注册，请先注册！</div> -->
            <!-- 验证码 -->
            <div class="password" :class="[err_phoneNumber ? '' : 'phoneNumer_gap', focusVerifi ? 'verifi_bottom' : '']">
              <div class="core">
                <img src="https://newstore.vynior.com/hthm10.png" alt="" />
              </div>
              <form :class="empty_verify == 1 ? 'clear_text' : ''">
                <input
                  type="password"
                  :placeholder="$t(`message.Home.verify`)"
                  autocomplete="off"
                  v-model="user_phone_verifi"
                  @blur="blur_verifi"
                  @focus="focus_verifi" />
              </form>
            </div>
            <!-- <div class="err_phonNumber" v-show="err_verify">验证码输入错误，请重新输入！</div>
          <div class="err_phonNumber" v-show="empty_verify">请输入验证码！</div>
          <div class="err_phonNumber" v-show="sx_verify">验证码已失效，请重新获取!</div> -->
            <!-- 接收不到验证码 -->
            <div class="remembers" :class="err_verify ? 'reduce_top' : ''" @click="not_verifi = 1">
              <span>{{ $t(`message.Home.Cantcode`) }}</span>
            </div>
          </div>

          <!-- 登录按钮 -->
          <div class="login" @click="success_mes" @keyup.enter="keyDown(e)" v-if="SessionStatus == false">
            <span>{{ $t(`message.Home.login`) }}</span>
          </div>
          <div class="login" @click="tokenVerfy" @keyup.enter="keyDown(e)" v-if="SessionStatus == true">
            <span>{{ $t(`message.Home.login`) }}</span>
          </div>
          <!-- //note 英文的切换登录方式 -->
          <div class="mobile" @click="checks(1)" v-show="check[0] == 1">Mobile number login</div>
          <div class="account" @click="checks(0)" v-show="check[1] == 1">Account password login</div>
          <!-- 立即注册 -->
          <div class="register" @click="registerControl">
            <span>{{ $t(`message.Home.register`) }}</span>
          </div>
        </div>

        <!--note   点击登录,弹出滑块验证 -->
        <div class="verify" v-show="verify_num">
          <div class="V_content" style="width: 499px; height: 450px">
            <div class="T_header">
              <div class="T_title" style="width: 196px; height: 28px; margin-right: 196px; font-size: 24px">{{ $t(`message.Home.Security`) }}</div>
              <div class="T_close" @click="T_close">
                <img src="https://newstore.vynior.com/logi_1.png" alt="" style="width: 38px; height: 38px" />
              </div>
            </div>

            <div class="page-slidecode" style="height: 250px; widows: 400px; left: 40px">
              <!-- <slide-verify ref="slideblock" :w=c_width :h=c_height :imgs="bgimgs" @success="onSuccess" @fail="onFail"
                @refresh="onRefresh" :slider-text="text"></slide-verify> -->
              <slide-verify
                ref="slideblock"
                :w="419"
                :h="210"
                :imgs="bgimgs"
                @success="onSuccess"
                @fail="onFail"
                @refresh="onRefresh"
                :slider-text="$t(`message.Home.slider`)"></slide-verify>
            </div>
          </div>
        </div>
        <!-- note    登录成功的表单 -->
        <div class="success_login" v-show="is_logins">
          <div class="Title">{{ $t(`message.Home.Title`) }}</div>
          <div class="login_content">
            <!-- 账号图片 -->
            <div class="img">
              <img src="https://newstore.vynior.com/HM.png" alt="" />
            </div>
            <div class="user_name">{{ user_name }}</div>
            <!-- 切换账号 -->
            <div class="change_id" @click="quit_id" v-show="$store.state.lang == 'cn'">
              <img src="https://newstore.vynior.com/hm123.png" alt="" />
            </div>
            <div class="change_id" @click="quit_id" v-show="$store.state.lang == 'en'">
              <img src="https://newstore.vynior.com/Group%2033426%402x.png" alt="" />
            </div>
            <!-- 退出账号 -->
            <div class="quit_id" @click="quit_id" v-show="$store.state.lang == 'cn'">
              <img src="https://newstore.vynior.com/hm33.png" alt="" />
            </div>
            <!-- 退出账号 -->
            <div class="quit_id" @click="quit_id" v-show="$store.state.lang == 'en'">
              <img src="https://newstore.vynior.com/Group%2033427%402x.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <!-- 注册遮罩 -->
      <div class="showReg" v-show="registerCon">
        <div class="RegDetail">
          <div class="Re_title">
            <span>{{ $t(`message.Home.Register`) }}</span>
          </div>
          <div class="Re_close" @click="changeRe">
            <img src="https://newstore.vynior.com/hthj11.png" alt="" />
          </div>
          <div class="QRcode">
            <img src="https://newstore.vynior.com/hthj12.png" alt="" />
          </div>
          <div class="text">
            <span>{{ $t(`message.Home.QR`) }}</span>
          </div>
        </div>
      </div>
      <!-- 接收不到验证码 -->
      <div class="showReg" v-show="not_verifi">
        <div class="RegDetail">
          <div class="Re_title">
            <span>{{ $t(`message.Home.Contact`) }}</span>
          </div>
          <div class="Re_close" @click="not_verifi = 0">
            <img src="https://newstore.vynior.com/hthj11.png" alt="" />
          </div>
          <div class="QRcode">
            <img src="https://newstore.vynior.com/hthj12.png" alt="" />
          </div>
          <div class="text">
            <span>{{ $t(`message.Home.QRcode`) }}</span>
          </div>
        </div>
      </div>
      <!-- 忘记密码层 -->
      <div class="Forget" v-show="Forget_pass">
        <!-- 发送验证码页面 -->
        <div class="For_Content" v-if="For_success">
          <div class="title">
            <span class="config_id">{{ $t(`message.Home.retrieve.Verification`) }}</span>
            <span class="turn_next" @click="second_lastStep">{{ $t(`message.Home.retrieve.Return`) }}</span>
          </div>
          <div class="For_content">
            <div class="For_detail">
              <p v-show="$store.state.lang == 'cn'">
                您正在对 <span>{{ user_name }}</span
                >进行找回密码操作<br />
                我们将会发送一条验证邮件到您的邮箱账号
              </p>
              <p v-show="$store.state.lang == 'en'">
                An email with verification will be sent to <span><br />{{ user_name }}</span>
              </p>
            </div>
            <!-- 验证码 -->
            <div class="verification">
              <div class="core">
                <img src="https://newstore.vynior.com/hthm10.png" alt="" />
              </div>
              <form>
                <input type="text" :placeholder="$t(`message.Home.retrieve.verifycode`)" autocomplete="off" v-model="verifi_num" maxlength="6" />
              </form>
              <div class="amend" :class="shows == false ? 'amend_two' : ''">
                <span v-show="shows" @click="send_nums">{{ $t(`message.Home.retrieve.Sendcode`) }}</span>
                <span v-show="shows == false && $store.state.lang == 'cn'">{{ counts }}秒后重发</span>
                <span v-show="shows == false && $store.state.lang == 'en'">Resend({{ counts }}s)</span>
              </div>
            </div>
            <!-- 错误提示 -->
            <div class="err_message" v-show="second_lastStep_num">{{ $t(`message.Home.retrieve.ErrorVerify`) }}</div>
            <!-- 下一步 button  -->
            <div class="nextOne" @click="For_verify">
              <span>{{ $t(`message.Home.retrieve.NextStep`) }}</span>
            </div>
            <!-- howtu -->
            <div class="RQcode">
              <img src="https://newstore.vynior.com/hthj12.png" alt="" />
            </div>
            <!-- instruction -->
            <div class="instruction" v-show="$store.state.lang == 'cn'">
              <p>
                若无法验证，您可以联系
                <span>灏图工作人员</span>进行相关操作
              </p>
            </div>
            <!-- instruction -->
            <div class="instruction" v-show="$store.state.lang == 'en'">
              <p>
                Cannot verify? Please
                <span>contact us for help </span>
              </p>
            </div>
          </div>
        </div>

        <!-- 完成验证,设置密码 -->
        <div class="For_Content" v-if="For_change">
          <div class="title">
            <span class="config_ids">{{ $t(`message.Home.retrieve.NewPwd`) }}</span>
            <span class="turn_next" @click="finally_lastStep">{{ $t(`message.Home.retrieve.Back`) }}</span>
          </div>
          <div class="For_content">
            <div class="For_detail">
              <p v-show="$store.state.lang == 'cn'">
                请设置 <span>{{ user_name }}</span
                >的新密码
              </p>
              <p v-show="$store.state.lang == 'en'">
                Please set a new password for <span>{{ user_name }}</span>
              </p>
            </div>
            <!-- 设置密码 -->
            <div class="verifications">
              <div class="core">
                <img src="https://newstore.vynior.com/hthm10.png" alt="" />
              </div>
              <form>
                <el-input
                  :placeholder="$t(`message.Header.EnterNewPWD`)"
                  v-model="input"
                  show-password
                  class="input_password"
                  @blur="cancletip"></el-input>
              </form>
              <div class="amend" @click="forgetPassword"></div>
            </div>
            <div class="err_message" v-show="finally_lastStep_num">{{ $t(`message.Home.retrieve.ErrorTip`) }}</div>
            <!-- 确认密码 -->

            <div class="verifications ver_two">
              <div class="core">
                <img src="https://newstore.vynior.com/hthm10.png" alt="" />
              </div>
              <form>
                <el-input :placeholder="$t(`message.Header.EnterNewPWDs`)" v-model="inputs" show-password class="input_password"></el-input>
              </form>
              <div class="amend" @click="forgetPassword"></div>
            </div>
            <div class="err_message" v-show="finally_lastStep_nums">{{ $t(`message.Home.retrieve.ErrorTips`) }}</div>
            <!-- 下一步 button  -->
            <div class="nextOne nextOnes" @click="For_end">
              <span>完成</span>
            </div>
          </div>
        </div>
        <!-- 要修改的提醒弹窗 -->
        <div class="password_tip" v-show="password_tip && $store.state.lang == 'cn'">
          <div class="text_content">
            8位密码长度 <br />
            至少包含数字、大小写字母及特殊字符中的两种 <br />
            不使用相同的数字或字符，如111111、aaaaaa<br />
            不使用连续升序或降序的数字或字母，如123456、abcdef<br />
            <!-- 不使用身份证后8位作为密码<br /> -->
            密码不包含账号
          </div>
        </div>
        <!-- 要修改的提醒弹窗 -->
        <div class="password_tip" v-show="password_tip && $store.state.lang == 'en'">
          <div class="text_content">
            Password must<br />
            - Be at least 8 characters in length <br />
            - Include at least two of the following elements: <br />
            At least one letter (upper or lowercase)<br />
            At least one number<br />
            At least one special character (!"#$%&' ()*+,-./:;<=>?@[]^_` {|}~)<br />
            <!-- 不使用身份证后8位作为密码<br /> -->
            - Not include more than 2 identical characters (e.g. 11111,aaaaa) - Not include more than 2 consecutive 2 identical characters (e.g. 123,
            abc) <br />
            - Not include your user name<br />
          </div>
        </div>
        <!-- 确认找回密码的账号 -->
        <div class="For_Content" v-if="For_start">
          <div class="title">
            <span class="config_id">{{ $t(`message.Home.retrieve.ConfirmAccount`) }}</span>
            <span class="turn_next" @click="fist_lastStep">{{ $t(`message.Home.retrieve.Return`) }}</span>
          </div>
          <div class="For_contents">
            <div class="For_detail">
              <p>{{ $t(`message.Home.retrieve.EnterYour`) }}</p>
            </div>
            <!-- 账号 -->
            <div class="email">
              <div class="people">
                <img src="https://newstore.vynior.com/hthm9.png" alt="" />
              </div>
              <form>
                <input type="text" :placeholder="$t(`message.Home.retrieve.EmailAccount`)" v-model="user_name" />
              </form>
            </div>
            <div class="tip_message" v-show="fist_lastStep_num">{{ $t(`message.Home.retrieve.TryAgain`) }}</div>
            <!-- 下一步 button  -->
            <div class="nextOne nextOnes" @click="For_startn">
              <span>{{ $t(`message.Home.retrieve.NextStep`) }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- footer -->
      <div class="news">
        <div class="newss">
          <div class="title"  @click="test">
            <!-- <p>相关动态</p> -->
            <div class="title_text">{{this.$store.state.lang =="cn" ? "相关动态":"Correlation dynamics"}}</div>
          </div>

          <div class="newsContent">
            <div class="Dynamic" v-for="(item, index) in new_news" :key="index">
             <div>
               <span class="news_ynamic">{{ item.text_type }}</span>
              <span class="news_content" @click="go_news(index)">{{ item.text_title }}</span>
             </div>
              <span class="news_time">{{ item.mon }}月{{ item.day }}日</span>
            </div>
          </div>
        </div>

        <!-- video -->
            <div class="videoOne_title"   >{{this.$store.state.lang =="cn"? playerOptionsone.cn :  playerOptionsone.en}}</div>
        <div class="videoTwo_title"   >{{this.$store.state.lang =="cn"? playerOptionstwo.cn :  playerOptionstwo.en}}</div>
        <div class="move">
          <div class="videoOne" v-if="videoInfo">
             <!-- <div>{{playerOptionsone.en}}</div> -->
            
            
            <video-player
              class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              :options="playerOptions"
              @play="onPlayerPlay($event, 0)"
              @pause="onPlayerPause($event)">
            </video-player>
          </div>
           
          <div class="videoTwo" v-if="videoInfo">
            
            <video-player
              class="video-player vjs-custom-skin"
              ref="videoPlayerss"
              :playsinline="true"
              :options="playerOptionss"
              @play="onPlayerPlays($event, 1)"
              @pause="onPlayerPauses($event)">
            </video-player>
          </div>
        </div>
        
        <!-- <span>{{ $store.state.age }}</span> -->
        <div class="video_sum" v-show="0">
          <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayers"
            :playsinline="true"
            :options="playerOptionss"
            @play="onPlayerPlays($event)"
            @pause="onPlayerPauses($event)">
          </video-player>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Header from '@/components/Header.vue';
import { HttpClient } from '@/utils/http';
import aa from '@/assets/image/aa.jpg'; //	如果使用网络图片资源就无需引入
import bb from '@/assets/image/bb.jpg';
import cc from '@/assets/image/cc.jpg';
import { videoPlayer } from 'vue-video-player';
import { message } from '@/utils/resetMessage';

// import { HttpStatusCode } from "axios";
let time1;
// let time2
export default {
  name: 'Home',
  data() {
    return {
      en: 'en',
      cn: 'cn',
      SessionStatus: false, //切换登录按钮(判断条件不一样)
      Not_email: 0, //未注册show
      videoInfo: true, //控制播放器显示隐藏
      videoPlayerInfo: false, //监听是否进入全屏
      eyeInfo: false,
      pasType: true, //true隐藏密码，false显示密码
      playerOptions: {
        //        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4',
            src: '', //你的视频地址（必填）
          },
        ],
        poster: '', //你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      playerOptionss: {
        //        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4',
            src: '', //你的视频地址（必填）
          },
        ],
        poster: '', //你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        //        controlBar: {
        //          timeDivider: true,
        //          durationDisplay: true,
        //          remainingTimeDisplay: false,
        //          fullscreenToggle: true  //全屏按钮
        //        }
      },
      // ! 视频标题
      playerOptionsone:{
            en:"",
            cn:"",
      },
       playerOptionstwo:{
            en:"",
            cn:"",
       },
      news_model: {
        text_type: '',
        text_title: '',
        text_comtent: '',
        time: '',
        year: '',
        mon: '',
        day: '',
      },
      new_userid: '',
      new_news: [],
      time: '', //返回时间 东八区
      news_data: [], //新闻接口的信息
      c_height: 200, //滑块画布的大小
      c_width: 310, //滑块画布的大小
      remember_day: false, //15天
      sx_verify: 0, // 失效显示提示
      empty_verify: 0, //未输入验证码显示的提示
      video_arr: [], //存储首页的视频和封面
      not_verifi: 0, //控制接收不到验证码ui
      focusVerifi: 0, //控制验证码焦点
      focusPwd: 0, //控制密码焦点
      focusEmail: 0, //控制邮箱焦点
      focus_numbers: [0, 0, 0, 0, 0], //获得焦点控制样式
      //手机登录接收验证码
      dis: false,
      show: true,
      shows: true,
      isGrey: false, //按钮样式
      timer: null, //设置计时器,
      timers: null, //设置计时器,
      count: 0,
      counts: '',
      // 控制视频开关
      videoIsPlay: false,
      videoIsPlay2: false,
      // 轮播图 图片地址
      carouseData: [
        { url: require('@/assets/image/htsy1.png') },
        { url: require('@/assets/image/htsy1.png') },
        { url: require('@/assets/image/htsy1.png') },
      ],
      carouse_arr: [], //接收轮播图图片
      // 切换语言的样式控制
      change_language: 1,
      msg: '',
      bgimgs: [aa, bb, cc],
      // bgimgs:[],	//	如果使用网络图片资源就用该值
      text: '向右拖动滑块填充拼图',

      // 滑块验证控制
      verify_num: 0,
      Res: '',
      // login_判断
      is_login: true, //未登录
      is_logins: false, //登录
      // 新闻数组
      news: [
        {
          ynamic: '公司动态',
          content: 'FAST快速敏捷高效精准',
          time: '今日 14：35',
        },
        {
          ynamic: '公司动态',
          content: 'FAST领先智能预测系统首发',
          time: '08月20日',
        },
        {
          ynamic: '公司动态',
          content: 'FAST人工智能、机器学习、专家系统的完美结合',
          time: '08月20日',
        },
        {
          ynamic: '业内动态',
          content: 'FAST快速鉴别产品在“江湖”中位置',
          time: '08月20日',
        },
        {
          ynamic: '公司动态',
          content: 'FAST领先智能预测系统隆重上市',
          time: '08月20日',
        },
        {
          ynamic: '公司动态',
          content: 'FAST幕后花絮分享',
          time: '08月20日',
        },
        {
          ynamic: '公司动态',
          content: 'FAST推荐会即将开启',
          time: '2021年09月06日',
        },
      ],

      check: [1, 0],
      // 注册虚化
      registerCon: 0,
      // 忘记密码
      Forget_pass: 0, //背景
      For_success: 0, //第二步
      For_change: 0, //第三步
      user_name: '',
      // 手机号登录信息
      user_phone_verifi: '',
      user_phone: '',

      // 手机登录 err提示
      err_phoneNumber: 0,
      err_verify: 0,
      user_phone_verifival: '',
      // 忘记密码错误提示控制
      verifi_num: '',
      fist_lastStep_num: 0,
      second_lastStep_num: 0,
      finally_lastStep_num: 0,
      finally_lastStep_nums: 0,
      // 确认账号隐藏
      For_start: 0, //第一步
      // 密码提示
      password_tip: 0,

      //邮箱错误提示
      email_err: 0,
      password_err: '',
      // el_input v-model数据同步
      input: '',
      inputs: '',
      password_val: '',
      email_val: '',
    };
  },
  computed: {
    // ...mapState(["age"]),
    player() {
      return this.$refs.videoPlayer.player;
    },
    // 超时后，登录按钮的判断
  },
  components: {
    Header,
    // videoPlayer
    videoPlayer,
  },
  watch: {
    email_err(newval) {
      if (newval == 1) {
        this.Not_email = 0;
      }
    },
    password_err(newval) {
      if (newval == 1) {
        this.Not_email = 0;
      }
    },
    '$store.state.overTimeToken'(newValue) {
      if (newValue == true) {
        console.log('进入watch :>> ');
        this.changeLogin();
      }
    },
    "$store.state.lang":{
       immediate:true,
      deep:true,
      handler(){
        console.log("准备切换公司的数据了");
        console.log(this.new_news);
      //  this.new_news.forEach((item,index)=>{
      //       if(item.text_type){

      //       }
      //  })

      if(this.$store.state.lang =="cn"){
          this.new_news.forEach((item,index)=>{
            if(item.text_type =="Company Dynamics"){
               this.new_news[index].text_type = "公司动态"
            }
             if(item.text_type =="Enterprise Dynamics"){
               this.new_news[index].text_type = "业内动态"
            }
       })

      }else{
          this.new_news.forEach((item,index)=>{
            if(item.text_type =="公司动态"){
               this.new_news[index].text_type = "Company Dynamics"
            }
             if(item.text_type =="业内动态"){
               this.new_news[index].text_type = "Enterprise Dynamics"
            }
       })
        

        console.log("公司数据英文");
      }
      },
     
    }
  },
  methods: {
    async tokenVerfy() {
      await HttpClient.post('subuser/tokenverify', {
        token: this.$store.state.token,
      }).then((res) => {
        if (res.status == 1) {
          message({
            showClose: true,
            message: '登录超时，请重新登录！',
            type: 'warning',
          });
          localStorage.removeItem('userid');
          // localStorage.setItem("userInfo", JSON.stringify(userInfo));
        } else {
          //note 123
          this.is_login = 0;
          this.is_logins = true;
          sessionStorage.setItem('sessionToken', res.sessionToken);
        }
      });
      this.change_local();
      this.SessionStatus = false;
    },
    changeLogin() {
      this.is_login = true;
      this.is_logins = false;
      this.$store.commit('$_change_overTimeToken', false);
    },
    eyeChange() {
      clearTimeout(time1);
      this.$refs.content.focus();
      // clearTimeout(time2)
      // clearTimeout(time1)
      // this.focusPwd=1
      // this.focus_pwd()
      // time2=setTimeout(() => {
      // this.eyeInfo=false
      // }, 200);
      this.pasType = !this.pasType;
      console.log('点击眼睛');
    },
    test() {
      // this.$refs.videoPlayer.player.pause() // 暂停
      this.$refs.videoPlayer.player.src(this.video_arr[0].video_path); // 重置进度条

      let aa = document.getElementById('vjs_video_10101_html5_api');
      console.log('aa', aa);
    },
    onPlayerFullScreenchange() {
      // player.exitFullscreen() //强制退出全屏，恢复正常大小
      // this.videoDialogVisible = true
      alert('退出全屏');
    },
    onPlayerPlay() {
      // if(i==0){
      // }else if(i==1){
      // }
      console.log('开始播放视频1');
      this.$refs.videoPlayerss.player.pause();

      // alert("play");
    },
    onPlayerPause(player) {
      console.log(player);

      // alert("pause");
    },
    onPlayerPlays() {
      this.$refs.videoPlayer.player.pause();

      // alert("play");
      console.log('开始播放视频2');
    },
    onPlayerPauses() {
      // alert("pause");
      // this.$refs.videoPlayers.player.src(src) // 重置进度条
    },
    onPlayerTimeupdate() {
      // console.log(player)
      // alert("退出全屏")
    },
    // 登录成功---按钮
    async success_mes() {
      var _this = this;
      // console.log(this.email_val + "123" + this.password_val);

      //两个data不是空的时候,点击登录发送请求.
      // note邮箱验证
      if (_this.check[0] != 0) {
        let emailReg_fist = new RegExp(/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/);
        if (!emailReg_fist.test(_this.email_val)) {
          this.email_err = 1;
        } else {
          if (_this.password_val != '' && _this.email_val != '') {
            let emailReg = new RegExp(/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/);
            if (!emailReg.test(_this.email_val)) {
              message({
                showClose: true,
                type: 'warning',
                message: '账号不正确，请重新输入！',
              });
              return;
            }
            await HttpClient.post('subuser/logincheck', {
              // email: "lily.sun@howtonpd.com",
              // password: "ctm-FAST2022yqsl",
              type: 'email', //邮件/手机验证类型
              email: _this.email_val, //电子邮箱，比如 example@qq.com
              password: _this.password_val, //密码
              // verifycode: "012345", //手机验证码
            }).then((res) => {
              _this.Res = res;

              console.log(res);
              console.log(_this.Res);
              // return this.dataAll;
              if (_this.Res.errorindex == '00002') {
                // _this.email_err = 0;
                _this.password_err = 1;
                // console.log(_this.Res);

                // message({ showClose: true, type: 'warning', message: '密码不正确，请重新输入！!' });
              } else if (_this.Res.errorindex == '00001') {
                _this.email_err = 0;
                _this.Not_email = 1;
                _this.password_err = 0;
                // message({ showClose: true, type: 'warning', message: '邮箱账号未注册，请重新输入！!' });
              } else {
                _this.Not_email = 0;
                _this.email_err = 0;
                _this.password_err = 0;

                // console.log(this.$store.state.age);
                //显示滑块
                _this.verify_num = 1;
                _this.$refs.slideblock.reset(); //刷新图片重新滑块
              }
            });
          } else {
            if (_this.password_val == '' && _this.email_val != '') {
              // message({ showClose: true, type: 'warning', message: '密码不正确，请重新输入！' });
              this.password_err = 1;
              this.Not_email = 0;
            } else if (_this.password_val != '' && _this.email_val == '') {
              message({
                showClose: true,
                type: 'warning',
                message: '账号不正确，请重新输入！',
              });
            } else if (_this.password_val == '' && _this.email_val == '') {
              // message({ showClose: true, type: 'warning', message: '账号（密码）不正确，请重新输入!' });
              this.password_err = 1;
              this.Not_email = 0;
              this.email_err = 1;
            }
          }
        }
      }
      // ------------------------------------------------------
      // note手机验证
      let judgeFn = new RegExp(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/);
      // 判断是否为手机登录模块
      if (_this.check[1] != 0) {
        // 校验手机号的正确
        if (_this.user_phone != '' && judgeFn.test(_this.user_phone)) {
          // 校验  发送验证码的状态
          if (_this.Res == 'success') {
            console.log('进入请求');
            await HttpClient.post('subuser/logincheck', {
              type: 'phone', //邮件/手机验证类型三种，register,login,reset
              phone: _this.user_phone, //手机号码
              verifycode: _this.user_phone_verifi,
            }).then((res) => {
              // console.log(res);
              _this.user_phone_verifival = res;
              console.log(_this.user_phone_verifival);
              if (_this.user_phone_verifi == '') {
                message({
                  showClose: true,
                  type: 'warning',
                  message: this.$t(`message.Message.codeError`),
                });
              } else {
                if (_this.user_phone_verifival.checkstatus == true) {
                  console.log('6666');
                  _this.err_verify = 0;
                  //显示滑块
                  _this.verify_num = 1;
                  _this.$refs.slideblock.reset(); //刷新图片重新滑块
                } else if (_this.user_phone_verifival.errorindex == '00004') {
                  message({
                    showClose: true,
                    type: 'warning',
                    message: '验证码已失效，请重新获取!',
                  });
                } else {
                  message({
                    showClose: true,
                    type: 'warning',
                    message: this.$t(`message.Message.codeError`),
                  });
                  console.log('err');
                }
              }

              //  判断返回状态 是否为true
            });
          } else {
            message({
              showClose: true,
              type: 'warning',
              message: this.$t(`message.Message.Verification`),
            });
          }
          // 验证码判断
        } else {
          message({
            showClose: true,
            type: 'warning',
            message: this.$t(`message.Message.phoneNumber`),
          });
        }
      }
    },
    // 回车按键登录
    keyDown(e) {
      if (e.keyCode == 13) {
        this.success_mes(); // 定义的登录方法
        // console.log("123123123");
      }
    },
    // 发送验证码样式
    getCode() {
      if (this.user_phone == '') {
        message({
          showClose: true,
          type: 'warning',
          message: this.$t(`message.Message.phoneNumber`),
        });
        return;
      }

      if (this.count > 0) {
        message({
          showClose: true,
          type: 'warning',
          message: this.$t(`message.itemAnalyse.nohandle`),
        });
      } else {
        let judgeFn = new RegExp(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/);
        if (judgeFn.test(this.user_phone)) {
          this.err_phoneNumber = 0; //提示错误信息
          // 60s倒计时

          //符合要求，发送请求
          HttpClient.post('subuser/duanxin', {
            type: 'login', //邮件/手机验证类型三种，register,login,reset
            phone: this.user_phone, //手机号码
          }).then((res) => {
            if (res.status == 1) {
              message({
                showClose: true,
                type: 'warning',
                message: this.$t(`message.Message.accountNot`),
              });
              return;
            }
            let TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.isGrey = true;
              this.show = false;
              this.dis = true;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.dis = false;
                  this.isGrey = false;
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
            this.Res = res.message;
          });
        } else {
          message({
            showClose: true,
            type: 'warning',
            message: this.$t(`message.Message.phoneNumber`),
          });
        }
      }
    },
    // 发送验证码样式(邮箱验证)
    getCodes() {
      // 60s倒计时
      let TIME_COUNT = 60;
      if (!this.timers) {
        this.counts = TIME_COUNT;
        // this.isGrey = true;
        this.shows = false;
        // this.dis = true;
        this.timers = setInterval(() => {
          if (this.counts > 0 && this.counts <= TIME_COUNT) {
            this.counts--;
          } else {
            // this.dis = false;
            // this.isGrey = false;
            this.shows = true;
            clearInterval(this.timers);
            this.timers = null;
          }
        }, 1000);
      }
    },
    // 退出账号
    quit_id() {
      if (this.$route.path == '/home') {
        this.is_login = true;
        this.is_logins = false;
        this.email_val = '';
        this.password_val = '';
        // localStorage.clear();
        localStorage.removeItem('userInfo');
        localStorage.removeItem('userid');
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_email');
        localStorage.removeItem('token');
      } else {
        this.$router.push({ path: '/home' });
        this.is_login = true;
        this.is_logins = false;
        this.email_val = '';
        this.password_val = '';
        // localStorage.clear();
        localStorage.removeItem('userInfo');
        localStorage.removeItem('userid');
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_email');
        localStorage.removeItem('token');
      }
    },
    // 进入项目
    go() {
      this.$router.push({
        name: 'myproject',
      });
    },
    // 切换邮箱登录和手机号登录
    checks(val) {
      for (let index = 0; index < this.check.length; index++) {
        this.check[index] = 0;
      }
      this.$set(this.check, val, 1);
      // console.log("check" + this.check[0]);
    },
    // 注册遮罩
    registerControl() {
      this.registerCon = 1;
    },
    // 取消注册遮罩
    changeRe() {
      this.registerCon = 0;
    },
    // 忘记密码遮罩
    // 忘记密码
    forgetPassword() {
      this.Forget_pass = 1;
      this.For_start = 1;
    },

    // 关闭滑块验证
    T_close() {
      this.verify_num = 0;
    },
    // 滑块验证方法1
    onSuccess() {
      // let _this = this;
      this.msg = '登录成功~';
      // this.$router.go(0);
      // this.$router.push({ path: "/myproject" });

      this.is_login = false;
      this.is_logins = true;
      this.verify_num = 0; //登录成功关闭滑块验证

      if (this.email_val == '') {
        this.user_name = this.user_phone;
        console.log(this.user_name);
      } else {
        this.user_name = this.email_val;
        console.log(this.user_name);
      }

      if (this.Res == 'success') {
        this.Res = this.user_phone_verifival;
      }
      console.log('this.user_phone_verifival :>> ', this.user_phone_verifival);
      console.log('this.Res :>> ', this.Res);

      localStorage.setItem('user_name', JSON.stringify(this.user_name));
      localStorage.setItem('user_email', JSON.stringify(this.Res.email));
      console.log('111');
      this.$store.commit('$_setToken', this.Res.token);
      this.$store.commit('$_setSessinoToken', this.Res.sessionToken);
      this.$store.commit('$_setUserid', this.Res.userid);
      console.log(this.Res.token, this.Res.userid);

      //弹窗提示登录成功
      this.$message({
        showClose: true,
        message: this.$t(`message.Message.Login`),
        type: 'success',
      });
      let userInfo = {
        isLogin: true,
      };
      this.$store.commit('$_setToken', this.Res.token);
      this.$store.commit('$_setSessinoToken', this.Res.sessionToken);
      this.$store.commit('$_setUserid', this.Res.userid);
      localStorage.setItem('userInfo', JSON.stringify(userInfo));

      // 跳转路由
      this.$router.push({
        path: '/myproject',
        query: {
          pwd: this.password_val,
        },
      });
    },
    // 滑块验证方法2
    onFail() {
      this.msg = '登录失败！';
      // this.onRefresh;
    },
    // 滑块验证方法3
    onRefresh() {
      this.msg = '重新生成验证码';
      console.log('重新生成验证码');
    },

    // 视频控制
    play_video() {
      this.videoIsPlay = !this.videoIsPlay;
      const video = this.$refs.videoRef;
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    },
    play_video2() {
      this.videoIsPlay2 = !this.videoIsPlay2;
      const video = this.$refs.videoRef2;
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    },
    // 进入全屏1
    handleFullScreen(e) {
      this.launchFullscreen(e.target);
      console.log(e);
    },

    // 进入全屏1
    launchFullscreen(element) {
      //此方法不可以在異步任務中執行，否則火狐無法全屏
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.oRequestFullscreen) {
        element.oRequestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen();
      } else {
        var docHtml = document.documentElement;
        var docBody = document.body;
        var videobox = element;
        var cssText = 'width:100%;height:100%;overflow:hidden;';
        docHtml.style.cssText = cssText;
        docBody.style.cssText = cssText;
        videobox.style.cssText = cssText + ';' + 'margin:0px;padding:0px;';
        document.IsFullScreen = true;
      }
    },

    // 退出全屏1
    handleExitFullScreen(e) {
      console.log('123');
      this.exitFullscreen(e.target);
    },

    // 退出全屏1
    exitFullscreen(element) {
      console.log('12312321312');
      if (document.exitFullscreen) {
        document.exitFullscreen();
        console.log('111111');
        document.pause();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
        document.pause();
        console.log('2222');
        document.pause();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
        console.log('3333');
        document.pause();
      } else if (document.oRequestFullscreen) {
        document.oCancelFullScreen();
        console.log('44444');
        document.pause();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
        document.pause();
        console.log('5555');
      } else {
        var docHtml = document.documentElement;
        var docBody = document.body;
        console.log('5555');
        var videobox = element;
        docHtml.style.cssText = '';
        docBody.style.cssText = '';
        videobox.style.cssText = '';

        document.IsFullScreen = false;
        document.pause();
      }
      if (!element.detail.fullScreen) {
        this.videoContext.stop();
      }
    },

    // 输入账号,下一步
    async For_startn() {
      await HttpClient.post('subuser/emailverify', {
        email: this.user_name,
      }).then((res) => {
        console.log(res);
        if (res.status == 0) {
          this.new_userid = res._id;
          this.user_name = res.email;
          this.For_start = 0;
          this.For_success = 1;
          this.fist_lastStep_num = 0;
        } else {
          message({
            showClose: true,
            message: this.$t(`message.Message.notRegistered`),

            type: 'warning',
          });
          this.fist_lastStep_num = 1;
        }
      });
    },
    // 验证码的下一步
    async For_verify() {
      HttpClient.post('subuser/resetcheck', {
        email: this.user_name,
        verifycode: this.verifi_num,
      }).then((res) => {
        console.log(res);
        if (res.checkstatus == true) {
          this.For_success = 0;
          this.For_change = 1;
          this.second_lastStep_num = 0;
          this.password_tip = 1;
        } else {
          this.second_lastStep_num = 1;
        }
      });
    },

    //发送验证码
    async send_nums() {
      // 60s倒计时
      let TIME_COUNT = 60;
      if (!this.timers) {
        this.counts = TIME_COUNT;
        // this.isGrey = true;
        this.shows = false;
        // this.dis = true;
        this.timers = setInterval(() => {
          if (this.counts > 0 && this.counts <= TIME_COUNT) {
            this.counts--;
          } else {
            // this.dis = false;
            // this.isGrey = false;
            this.shows = true;
            clearInterval(this.timers);
            this.timers = null;
          }
        }, 1000);
      }

      await HttpClient.post('subuser/emailreset', {
        email: this.user_name,
      }).then((res) => {
        console.log(res);
      });
    },
    // 确认密码的完成按钮
    async For_end() {
      if (this.input == this.inputs) {
        if (this.input == 'Welcome123') {
          this.$message.error('密码不能与初始密码相同！');
        }
      }
      // var judgeFn = new RegExp(
      //   /^(?![A-Za-z0-9]+$)(?![a-z0-9\W]+$)(?![A-Za-z\W]+$)(?![A-Z0-9\W]+$)[a-zA-Z0-9\W]{8}$/
      // );
      var judgeFn = new RegExp(/^(?=.*[0-9])(?=.*[a-zA-Z])[0-9A-Za-z~!@#$%^&*._?]{8}$/);
      if (this.input == this.inputs && judgeFn.test(this.input)) {
        this.For_change = 0;
        this.Forget_pass = 0;
        await HttpClient.post('subuser/update', {
          updates: {
            _id: this.new_userid,
            password: this.input,
          },
        }).then((res) => {
          console.log(res);
        });
      } else {
        this.finally_lastStep_nums = 1;
      }

      // this.input = null;
      // this.inputs = null;
    },
    // 第一个上一步操作,返回到首页登录
    fist_lastStep() {
      this.user_name = '';
      this.Forget_pass = 0;
      this.For_start = 0;
    },
    // 第二个上一步操作,返回到确认账号
    second_lastStep() {
      this.For_start = 1;
      this.For_success = 0;
    },
    // 第三个上一步操作,返回到发送验证码
    finally_lastStep() {
      this.input = '';
      this.inputs = '';
      (this.For_change = 0), //第三步
        (this.For_success = 1);
      this.password_tip = 0;
    },
    // 关闭提示
    cancletip() {
      this.password_tip = 0;
      // var judgeFn = new RegExp(
      //   /^(?![A-Za-z0-9]+$)(?![a-z0-9\W]+$)(?![A-Za-z\W]+$)(?![A-Z0-9\W]+$)[a-zA-Z0-9\W]{8}$/
      // );
      var judgeFn = new RegExp(/^(?=.*[0-9])(?=.*[a-zA-Z])[0-9A-Za-z~!@#$%^&*._?]{8}$/); //8位字母+数字/数字+特殊字符/特殊字符+字母

      if (judgeFn.test(this.input)) {
        this.finally_lastStep_num = 0;
      } else {
        this.finally_lastStep_num = 1;
      }
    },

    // 输入手机号
    rex_number() {
      this.focus_numbers = 0;
    },
    // 获取焦点
    focus_number() {
      this.focus_numbers = 1;
      this.err_phoneNumber = 0;
    },
    //获取邮箱焦点样式变化
    focus_email() {
      this.focusEmail = 1;
      this.email_err = 0;
    },
    blur_email() {
      this.focusEmail = 0;
    },
    // 获取密码焦点判断
    focus_pwd() {
      clearTimeout(time1);
      this.eyeInfo = true;
      this.focusPwd = 1;
      this.password_err = 0;
      console.log('获取焦点');
    },
    blur_pwd() {
      time1 = setTimeout(() => {
        this.eyeInfo = false;
      }, 200);

      this.focusPwd = 0;
    },
    // 获取验证码焦点
    focus_verifi() {
      this.focusVerifi = 1;
      this.empty_verify = 0;
      this.err_verify = 0;
      this.sx_verify = 0;
    },
    blur_verifi() {
      this.focusVerifi = 0;
    },
    // 跳转到新闻页面
    go_news(val) {
      console.log(this.news[val].content, this.is_login, this.is_logins);
      this.$router.push({
        name: 'news',
        params: {
          title: this.news[val].content,
          change_num: this.is_login,
          change_num2: this.is_logins,
          content: this.new_news[val],
        },
      });
    },
    // 视频接口
    async req_video() {
      await HttpClient.post('homeVideo/findTrue', {}).then((res) => {
        console.log(res);
        this.video_arr = res;
        this.playerOptions.sources[0].src = this.video_arr[0].video_path;
        this.playerOptionss.sources[0].src = this.video_arr[1].video_path;
        this.playerOptionss.poster = this.video_arr[1].poster_path || 'https://newstore.vynior.com/hm1.png';
        this.playerOptions.poster = this.video_arr[0].poster_path || 'https://newstore.vynior.com/hm1.png';
        this.playerOptionsone.cn = this.video_arr[0].video_name
         this.playerOptionsone.en= this.video_arr[0].enVideo_name
          this.playerOptionstwo.cn = this.video_arr[1].video_name
         this.playerOptionstwo.en= this.video_arr[1].enVideo_name
      });
    },

    // 轮播图接口
    async req_img() {
      await HttpClient.post('homeImg/findTrue').then((res) => {
        console.log(res);
        this.carouse_arr = res;
      });
    },

    // 新闻接口

    async req_news() {
      await HttpClient.post('homeText/findByShow').then((res) => {
        console.log(res);
        this.news_data = res;
        console.log(this.news_data);
        // console.log(new Date());

        if (this.news_data.length != 0) this.time = moment.utc(this.news_data[0].updatedAt).local().format('YYYY-MM-DD HH:mm:ss');
        // console.log(this.time);
        for (let index = 0; index < this.news_data.length; index++) {
          this.new_news.push(JSON.parse(JSON.stringify(this.news_model)));
          this.new_news[index].text_type = this.news_data[index].text_type;
          this.new_news[index].text_title = this.news_data[index].text_title;
          this.new_news[index].year = this.news_data[index].updatedAt.slice(0, 4);
          this.new_news[index].mon = this.news_data[index].updatedAt.slice(5, 7);
          this.new_news[index].day = this.news_data[index].updatedAt.slice(8, 10);
          this.new_news[index].text_content = this.news_data[index].text_content;
        }
        console.log(this.new_news);
      });
    },
    //校验token
    async req_token() {
      console.log('token登陆');

      await HttpClient.post('subuser/tokenverify', {
        token: this.$store.state.token,
      }).then((res) => {
        if (res.status == 1) {
          message({
            showClose: true,
            message: '登录超时，请重新登录！',
            type: 'warning',
          });
          localStorage.removeItem('userid');
          // localStorage.setItem("userInfo", JSON.stringify(userInfo));
        } else {
          sessionStorage.setItem('sessionToken', res.sessionToken);
        }
      });
      this.change_local();
      console.log(localStorage.getItem('userid'));
    },
    //判断是否有localstorage

    change_local() {
      if (localStorage.getItem('userid') == null) {
        console.log(123);
        this.quit_id();
      }
    },
    //切换语言
    langChange(val) {
      console.log(val);
      localStorage.setItem('lang', val);
      this.$i18n.locale = val;
      this.$store.commit('$_changeLang', val);
      console.log('改变了Lang');
    },
  },
  mounted() {
    window.addEventListener('fullscreenchange', () => {
      this.videoPlayerInfo = !this.videoPlayerInfo;
      if (this.videoPlayerInfo == false) {
        this.videoInfo = false;
        setTimeout(() => {
          this.videoInfo = true;
        }, 100);
        console.log('重置播放器');
      }
      console.log('进入全屏');
    });
    //超时操作/
    this.is_logins = this.$store.state.overTimeToken;
    this.user_name = JSON.parse(localStorage.getItem('user_name'));
    // console.log('this.is_logins :>> ', this.is_logins);
    // console.log('this.is_login :>> ', this.is_login);
    this.is_login = !this.is_logins;
    //填充用户信息
    if (this.is_logins == false) {
      this.SessionStatus = true;
      this.email_val = this.user_name;
      this.password_val = '******';
    }
    // 正常刷新登录状态
    let userinfo = JSON.parse(localStorage.getItem('userInfo'));
    console.log('userinfo :>> ', userinfo);
    if (this.is_logins == true && userinfo == null) {
      this.is_logins = false;
      this.is_login = true;
    }
    // console.log('this.is_logins :>> ', this.is_logins);
    // console.log('this.is_login :>> ', this.is_login);
    // 绑定监听事件
    window.addEventListener('keydown', this.keyDown);
    this.req_img();
    this.req_video();
    this.req_news();
    if (sessionStorage.getItem('login') == null) {
      this.req_token();
    }
    // console.log();
    // console.log('sessionStorage.getItem() :>> ', localStorage.getItem('lang'));
    if (localStorage.getItem('lang') == null && this.$store.state.lang == undefined) {
      localStorage.setItem('lang', 'cn');
      this.$store.commit('$_changeLang', 'cn');
      console.log('改变了Lang');
    } else if (localStorage.getItem('lang') == null && this.$store.state.lang != undefined) {
      localStorage.setItem('lang', this.$store.state.lang);
    }
    // console.log('localStorage.getItem() :>> ', localStorage.getItem('lang'));
    // this.change_local()
  },
  created() {
    // let that = this;
    console.log(localStorage.lang);
    // that.selectValue = localStorage.lang == undefined||localStorage.lang == null ? 'cn' : localStorage.lang;
    if (localStorage.lang == 'undefined' || localStorage.lang == null) {
      console.log('改变lang的值');
      localStorage.setItem('lang', this.$store.state.lang);
    }
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener('keydown', this.keyDown, false);
  },
};
</script>

<style lang="less" scoped>
@import url('../../style/Home/Home.less');
</style>
